import { useTranslation } from 'react-i18next'
import { Row, Col, Typography, Space } from 'antd';
import { MailTwoTone, PhoneTwoTone } from '@ant-design/icons';

const { Title, Text } = Typography;
const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]} >
      <Col span={24}>
        <Space direction="vertical">
          <Title level={5}>{t('activerecord.attributes.professional_services.contact_us.title')}
          </Title>
          <Space align="center">
          <PhoneTwoTone style={{ fontSize: '20px'}} />
            <Text>{t('activerecord.attributes.professional_services.contact_us.tel')}</Text>
          </Space>
          <Space align="center">
          <MailTwoTone style={{ fontSize: '20px'}} />
          <Text>{t('activerecord.attributes.professional_services.contact_us.mail')}</Text>
          </Space>
        </Space>
      </Col>
    </Row>
  );
};

export default ContactUs;
