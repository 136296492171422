import { useTranslation } from 'react-i18next'
import QServiceLayout from 'components/layout/QServiceLayout';
import { Row, Col, Typography, Flex }from 'antd';
import ContactUs from 'components/tenant/additional_services/ContactUs';

const { Title, Paragraph } = Typography;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.professional_services'),
      },
      {
          title: t('activerecord.attributes.layout.balance_entries'),
      },
  ]
}

const BalanceEntries = () => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  
  return (
    <QServiceLayout title={t('activerecord.attributes.layout.balance_entries')} actionBtns={null} breadCrumbData={breadCrumbData} >
      <Row gutter={[32, 32]}>
        <Col md={12}>
          <Flex vertical justify='center' style={{ height: '100%' }}>
            <Title level={4}>{t('activerecord.attributes.professional_services.balance_entries.title')}</Title>
            <Paragraph style={{ textAlign: 'justify' }}>
              {t('activerecord.attributes.professional_services.balance_entries.description')}
            </Paragraph>
            <Paragraph style={{ textAlign: 'justify' }}>
              {t('activerecord.attributes.professional_services.balance_entries.key_benefits')}
            </Paragraph>
            <ContactUs />
          </Flex>
        </Col>
      </Row>
    </QServiceLayout>
  )
}

export default BalanceEntries