import BookKeepingIcon_I from 'assets/icons/bookkeeping_I.svg';
import BookKeepingIcon_II from 'assets/icons/bookkeeping_II.svg';
import BookKeepingIcon_III from 'assets/icons/bookkeeping_III.svg';
import BookKeepingIcon_IV from 'assets/icons/bookkeeping_IV.svg';
import BookKeepingIcon_V from 'assets/icons/bookkeeping_V.svg';
import BookKeepingIcon_VI from 'assets/icons/bookkeeping_VI.svg';
import Cleanup_I from 'assets/icons/cleanup_I.svg';
import Cleanup_II from 'assets/icons/cleanup_II.svg';
import TrainingIcon_I from 'assets/icons/training_I.svg';
import TrainingIcon_II from 'assets/icons/training_II.svg';
import TrainingIcon_III from 'assets/icons/training_III.svg';
import SetupIcon_I from 'assets/icons/setup_I.svg';
import SetupIcon_II from 'assets/icons/setup_II.svg';
import SetupIcon_III from 'assets/icons/setup_III.svg';
import ConsultationServiceIcon_I from 'assets/icons/ConsultationServiceIcon_I.svg'
import ConsultationServiceIcon_II from 'assets/icons/ConsultationServiceIcon_II.svg'
import InvoiceDesignIcon_I from 'assets/icons/InvoiceDesignIcon_I.svg';
import InvoiceDesignIcon_II from 'assets/icons/InvoiceDesignIcon_II.svg';
import TransferAccountsIcon_I from 'assets/icons/TransferAccountsIcon_I.svg';
import TransferAccountsIcon_II from 'assets/icons/TransferAccountsIcon_II.svg';
import VATIcon_I from 'assets/icons/VAT_I.svg';
import VATIcon_II from 'assets/icons/VAT_II.svg';
import i18next from 'i18next';



interface InfoItemData {
  title: string;
  content: string;
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>> | null;
}


interface ConsultationItem {
  title: string;
}

export const bookkeepingInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.I.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.I.content',
    Icon: BookKeepingIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.II.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.II.content',
    Icon: BookKeepingIcon_II
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.III.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.III.content',
    Icon: BookKeepingIcon_III
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.IV.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.IV.content',
    Icon: BookKeepingIcon_IV
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.V.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.V.content',
    Icon: BookKeepingIcon_V
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.VI.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.VI.content',
    Icon: BookKeepingIcon_VI
  },
]

export const cleanupInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.cleanup.info.I.title',
    content: 'activerecord.attributes.professional_services.cleanup.info.I.content',
    Icon: Cleanup_I
  },
  {
    title: 'activerecord.attributes.professional_services.cleanup.info.II.title',
    content: 'activerecord.attributes.professional_services.cleanup.info.II.content',
    Icon: Cleanup_II
  },
]

export const trainingInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.training.info.I.title',
    content: 'activerecord.attributes.professional_services.training.info.I.content',
    Icon: TrainingIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.training.info.II.title',
    content: 'activerecord.attributes.professional_services.training.info.II.content',
    Icon: TrainingIcon_II
  },
  {
    title: 'activerecord.attributes.professional_services.training.info.III.title',
    content: 'activerecord.attributes.professional_services.training.info.III.content',
    Icon: TrainingIcon_III
  },
]

export const setupInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.setup.info.I.title',
    content: 'activerecord.attributes.professional_services.setup.info.I.content',
    Icon: SetupIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.setup.info.II.title',
    content: 'activerecord.attributes.professional_services.setup.info.II.content',
    Icon: SetupIcon_II
  },
  {
    title: 'activerecord.attributes.professional_services.setup.info.III.title',
    content: 'activerecord.attributes.professional_services.setup.info.III.content',
    Icon: SetupIcon_III
  },
]
export const consultationServiceInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.consultation_service.info.I.title',
    content: 'activerecord.attributes.professional_services.consultation_service.info.I.content',
    Icon: ConsultationServiceIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.consultation_service.info.II.title',
    content: 'activerecord.attributes.professional_services.consultation_service.info.II.content',
    Icon: ConsultationServiceIcon_II
  },
]

export const invoiceDesignInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.invoice_design.info.I.title',
    content: 'activerecord.attributes.professional_services.invoice_design.info.I.content',
    Icon: InvoiceDesignIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.invoice_design.info.II.title',
    content: 'activerecord.attributes.professional_services.invoice_design.info.II.content',
    Icon: InvoiceDesignIcon_II
  },
]
export const transferAccountsInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.transfer_accounts.info.I.title',
    content: 'activerecord.attributes.professional_services.transfer_accounts.info.I.content',
    Icon: TransferAccountsIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.transfer_accounts.info.II.title',
    content: 'activerecord.attributes.professional_services.transfer_accounts.info.II.content',
    Icon: TransferAccountsIcon_II
  },
]
const vatARExtraItems: InfoItemData[] = i18next.language === 'en'? [] : [
  {
    title: 'activerecord.attributes.professional_services.vat.info.III.title',
    content: 'activerecord.attributes.professional_services.vat.info.III.content',
    Icon: null
  },
  {
    title: 'activerecord.attributes.professional_services.vat.info.IV.title',
    content: 'activerecord.attributes.professional_services.vat.info.IV.content',
    Icon: null
  }
]

export const vatInfoItems: InfoItemData[] = [
  {
    title: 'activerecord.attributes.professional_services.vat.info.I.title',
    content: 'activerecord.attributes.professional_services.vat.info.I.content',
    Icon: VATIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.vat.info.II.title',
    content: 'activerecord.attributes.professional_services.vat.info.II.content',
    Icon: VATIcon_II
  },
  ...vatARExtraItems
]

export const consultations: ConsultationItem[] = [
  {
    title: 'activerecord.attributes.professional_services.consultation_service.consultations.tax',
  },
  {
    title: 'activerecord.attributes.professional_services.consultation_service.consultations.accounting',
  },
  {
    title: 'activerecord.attributes.professional_services.consultation_service.consultations.financial',
  },
];