import { useTranslation } from "react-i18next";
import QServiceLayout from "components/layout/QServiceLayout";
import { Row, Col, Typography, Flex, Divider, List } from "antd";
import { consultationServiceInfoItems, consultations } from "tokens/additional_services";
import InfoItem from "components/tenant/additional_services/InfoItem";
import ContactUs from "components/tenant/additional_services/ContactUs";

const { Title, Paragraph, Text } = Typography;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t("activerecord.attributes.layout.professional_services"),
    },
    {
      title: t("activerecord.attributes.layout.consultation_service"),
    },
  ];
};

const ConsultationService = () => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();

  return (
    <QServiceLayout
      title={t("activerecord.attributes.layout.consultation_service")}
      actionBtns={null}
      breadCrumbData={breadCrumbData}
    >
      <Row gutter={[32, 32]}>
        <Col md={12}>
          <Flex vertical justify="center" style={{ height: "100%" }}>
            <Title level={4}> {t("activerecord.attributes.professional_services.consultation_service.title")}</Title>
            <Paragraph style={{ textAlign: "justify" }}>
              <Row gutter={[32, 32]}>
                <Col xs={24}>
                  <Text>{t("activerecord.attributes.professional_services.consultation_service.description")}</Text>
                  <List dataSource={consultations} renderItem={(item) => (
                      <List.Item style={{ padding: 0, border: "none" }}>
                        <Text>- {t(item.title)}</Text>
                      </List.Item>
                    )}
                  />
                  <Paragraph style={{ marginTop: "16px" }}>
                    {t("activerecord.attributes.professional_services.consultation_service.footer")}
                  </Paragraph>
                  <ContactUs />
                </Col>
              </Row>
            </Paragraph>
          </Flex>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[32, 32]}>
        {consultationServiceInfoItems.map((item, idx) => (
          <InfoItem key={idx} title={` ${t(item.title)}`} content={t(item.content)} Icon={item.Icon} />
        ))}
      </Row>
    </QServiceLayout>
  );
};

export default ConsultationService;
