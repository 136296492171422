import { Col, Flex, Typography } from "antd"
import QIcon from "components/layout/QIcon"
import React, { SVGAttributes } from "react"

const { Paragraph, Title } = Typography;

interface Props {
  Icon: React.FunctionComponent<SVGAttributes<SVGElement>> | null;
  title: string;
  content: string;
}

const InfoItem = ({ Icon, title, content }: Props) => {
  return (
    <Col md={12}>
      <Flex vertical gap={8}>
        <QIcon component={Icon} style={{fontSize: 48}} />
        <Title level={5}>{title}</Title>
        <Paragraph style={{ textAlign: 'justify' }}>{content}</Paragraph>
      </Flex>
    </Col>
  )
}

export default InfoItem