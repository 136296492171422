import { useTranslation } from 'react-i18next'
import QServiceLayout from 'components/layout/QServiceLayout';
import { Row, Col, Typography, Flex, Button, Divider } from 'antd';
import { vatInfoItems } from 'tokens/additional_services';
import InfoItem from 'components/tenant/additional_services/InfoItem';

const { Title, Paragraph } = Typography;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.professional_services'),
      },
      {
          title: t('activerecord.attributes.layout.vat_service'),
      },
  ]
}

const VAT = () => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  
  return (
    <QServiceLayout title={t('activerecord.attributes.layout.vat_service')} actionBtns={null} breadCrumbData={breadCrumbData}>
      <Row gutter={[32, 32]}>
        <Col md={12}>
          <Flex vertical justify='center' style={{ height: '100%' }}>
            <Title level={4}>{t('activerecord.attributes.professional_services.vat.title')}</Title>
            <Paragraph style={{ textAlign: 'justify' }}>
              {t('activerecord.attributes.professional_services.vat.description')}
            </Paragraph>
            <div>
              <Button type='primary' data-toggle="modal" data-target="#vatModal_">
                {t('activerecord.attributes.professional_services.vat.action')}
              </Button>
            </div>
          </Flex>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[32, 32]}>
        {vatInfoItems.map((item, idx) => (
          idx === 1? (
            <Col md={12} key={idx}>
              <Flex vertical gap={8}>
                <Flex>
                  {item.Icon && <item.Icon style={{ height: 48, width: 120 }}/>}
                </Flex>
                <Title level={5}>{idx + 1}. {t(item.title)}</Title>
                <Paragraph style={{ textAlign: 'justify' }}>{t(item.content)}</Paragraph>
              </Flex>
            </Col>
          ) : (
            <InfoItem key={idx} title={`${idx + 1}. ${t(item.title)}`} content={t(item.content)} Icon={item.Icon} />
          )
        ))}
      </Row>
    </QServiceLayout>
  )
}

export default VAT