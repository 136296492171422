import { useTranslation } from 'react-i18next'
import QServiceLayout from 'components/layout/QServiceLayout';
import { Row, Col, Typography, Flex ,Divider} from 'antd';
import {transferAccountsInfoItems} from 'tokens/additional_services';
import InfoItem from 'components/tenant/additional_services/InfoItem';
import ContactUs from 'components/tenant/additional_services/ContactUs';

const { Title, Paragraph } = Typography;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.professional_services'),
      },
      {
          title: t('activerecord.attributes.layout.transfer_accounts'),
      },
  ]
}

const TransferAccounts = () => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  
  return (
    <QServiceLayout title={t('activerecord.attributes.layout.transfer_accounts')} actionBtns={null} breadCrumbData={breadCrumbData} >
      <Row gutter={[32, 32]}>
        <Col md={12}>
          <Flex vertical justify='center' style={{ height: '100%' }}>
            <Title level={4}>{t('activerecord.attributes.professional_services.transfer_accounts.title')}</Title>
            <Paragraph style={{ textAlign: 'justify' }}>
              {t('activerecord.attributes.professional_services.transfer_accounts.description')}
            </Paragraph>
            <ContactUs />
          </Flex>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[32, 32]}>
        {transferAccountsInfoItems.map((item, idx) => (
          <InfoItem key={idx} title={` ${t(item.title)}`} content={t(item.content)} Icon={item.Icon} />
        ))}
      </Row>
    </QServiceLayout>
  )
}

export default TransferAccounts